import { Tab, Tabs } from '@mui/material';
import { Selection } from 'models/record.model';
import { FC, useState } from 'react';
import TabPanel from './DetailTabPanel';

interface TabTableProps {
  data?: any[];
  selection: Selection[];
  setSelection: (ref: Selection) => void;
  isHighlight: boolean;
}

const DetailTabsTable: FC<TabTableProps> = (props) => {
  const { data, selection, setSelection, isHighlight } = props;
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      {data && data.length > 1 && (
        <Tabs value={value} onChange={handleChange} variant='scrollable'>
          {data &&
            data.map((elem, idx) => {
              return <Tab key={idx} label={elem.name} />;
            })}
        </Tabs>
      )}
      {data &&
        data.map((elem, idx) => {
          return <TabPanel key={idx} value={value} index={idx} data={elem} selection={selection} setSelection={setSelection} isHighlight={isHighlight} />;
        })}
    </>
  );
};

export default DetailTabsTable;
